import {DateTime} from "luxon";

export const getDateFromISO = (date) => {
    return DateTime.fromISO(date, {zone: 'utc'})
}

export const formatDate = (date) => {
    return getDateFromISO(date).toLocaleString(DateTime.DATE_MED)
}

export const formatDateTime = (date) => {
    return getDateFromISO(date).toLocaleString(DateTime.DATETIME_MED)
}
